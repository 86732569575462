import React from 'react';
import walletIcon from '../../../assets/img/subscriptions/subscriptions-wallet-icon.svg';
import swapFeeIcon from '../../../assets/img/subscriptions/subscriptions-swap-fee-icon.svg';
import analyticsIcon from '../../../assets/img/subscriptions/subscriptions-analytics-icon.svg';
import ideasIcon from '../../../assets/img/subscriptions/subscriptions-ideas-icon.svg';
import advisorIcon from '../../../assets/img/subscriptions/subscriptions-advisor-icon.svg';
import adsIcon from '../../../assets/img/subscriptions/subscriptions-ads-icon.svg';
import pluginsIcon from '../../../assets/img/subscriptions/subscriptions-plugins-icon.svg';
import newsIcon from '../../../assets/img/subscriptions/subscriptions-news-icon.svg';
import teamActivityIcon from '../../../assets/img/subscriptions/subscriptions-team-activity-icon.svg';
import stakingIcon from '../../../assets/img/subscriptions/subscriptions-staking-icon.svg';
import betaAccessIcon from '../../../assets/img/subscriptions/beta-access.svg';
import unlockedIcon from '../../../assets/img/subscriptions/unlocked.svg';
import starterImage from '../../../assets/img/tiers/tier-starter-image.png';
import holderImage from '../../../assets/img/tiers/tier-holder-image.png';
import smartImage from '../../../assets/img/tiers/tier-smart-image.png';
import geniusImage from '../../../assets/img/tiers/tier-genius-image.png';

const Packages = () => (
    <section id="packages" className="packages-section">
        <div className="title-wrap animation">
            <h2>ApeScreener <span className='gradient'>Intelligence</span></h2>
        </div>
        <div className="items-wrap">
            <a href="#" className="item animation">
                <span className="text-wrap">
                    <div className="header">
                        <h5>Starter</h5>
                        <img src={starterImage} alt="Starter"/>
                    </div>
                    <div className="pricing">
                        <h6>Free</h6>
                    </div>
                    <ul>
                        <li><img src={walletIcon} alt="Wallets" /><p>Track up to 3 wallets</p></li>
                        <li><img src={swapFeeIcon} alt="Swap & Strategies Fees" /><p>1% Swap & Strategies Fees</p></li>
                        <li><img src={analyticsIcon} alt="Analytics" /><p>Basic Holder Analysis</p></li>
                        <li><img src={ideasIcon} alt="Ideas" /><p>Ideas</p></li>
                        <li><img src={advisorIcon} alt="Advisor" /><p>Basic Advisor</p></li>
                        <li><img src={adsIcon} alt="Ads" /><p>All Ads</p></li>
                    </ul>
                </span>
                {/* <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span> */}
            </a>
            <a href="#" className="item animation">
                <span className="text-wrap">
                    <div className="header">
                        <h5>Holder</h5>
                        <img src={holderImage} alt="Holder"/>
                    </div>
                    <div className="pricing">
                        <h6>Hold $100 in $APES</h6>
                        <h6>Hold $200 in partner token</h6>
                    </div>
                    <ul>
                        <li><img src={walletIcon} alt="Wallets" /><p>Track up to 5 wallets</p></li>
                        <li><img src={swapFeeIcon} alt="Swap & Strategies Fees" /><p>1% Swap & Strategies Fees</p></li>
                        <li><img src={analyticsIcon} alt="Analytics" /><p>Basic Holder Analysis</p></li>
                        <li><img src={ideasIcon} alt="Ideas" /><p>Ideas</p></li>
                        <li><img src={advisorIcon} alt="Advisor" /><p>Basic Advisor</p></li>
                        <li><img src={adsIcon} alt="Ads" /><p>All Ads</p></li>
                        <li><img src={stakingIcon} alt="Staking" /><p>Staking</p></li>
                    </ul>
                </span>
                {/* <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span> */}
            </a>
            <a href="#" className="item animation">
                <span className="text-wrap">
                    <div className="header">
                        <h5>Smart</h5>
                        <img src={smartImage} alt="Smart"/>
                    </div>
                    <div className="pricing">
                        <h6>Ξ0.08/year</h6>
                        <h6>0.1% $APES</h6>
                    </div>
                    <ul>
                        <li><img src={walletIcon} alt="Wallets" /><p>Track up to 10 wallets</p></li>
                        <li><img src={swapFeeIcon} alt="Swap & Strategies Fees" /><p>0.5% Swap & Strategies Fees</p></li>
                        <li><img src={analyticsIcon} alt="Analytics" /><p>Advanced Holder Analysis</p></li>
                        <li><img src={ideasIcon} alt="Ideas" /><p>AI Summarized Ideas</p></li>
                        <li><img src={advisorIcon} alt="Advisor" /><p>Smart LLM Advisor</p></li>
                        <li><img src={adsIcon} alt="Ads" /><p>Targeted/Relevant Ads</p></li>
                        <li><img src={stakingIcon} alt="Staking" /><p>Staking</p></li>
                        <li><img src={newsIcon} alt="News" /><p>Project News</p></li>
                        <li><img src={teamActivityIcon} alt="Team Activity" /><p>Team Activity</p></li>
                        <li><img src={pluginsIcon} alt="Plugins" /><p>Plugins</p></li>
                    </ul>
                </span>
                {/* <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span> */}
            </a>
            <a href="#" className="item item-color animation">
                <span className="text-wrap">
                    <div className="header">
                        <h5>
                            <span className="text">Genius</span>
                            <span className="badge">BEST ROI</span>
                        </h5>
                        <img src={geniusImage} alt="Genius"/>
                    </div>
                    <div className="pricing">
                        <h6>Ξ0.2/year</h6>
                        <h6>0.4% $APES</h6>
                    </div>
                    <ul>
                        <li><img src={walletIcon} alt="Wallets" /><p>Track up to 50 wallets</p></li>
                        <li><img src={swapFeeIcon} alt="Swap & Strategies Fees" /><p>0.25% Swap & Strategies Fees</p></li>
                        <li><img src={analyticsIcon} alt="Analytics" /><p>Advanced Holder Analysis</p></li>
                        <li><img src={ideasIcon} alt="Ideas" /><p>AI Summarized Ideas</p></li>
                        <li><img src={advisorIcon} alt="Advisor" /><p>Genius LLM Advisor</p></li>
                        <li><img src={adsIcon} alt="Ads" /><p>Can opt-out of Ads</p></li>
                        <li><img src={stakingIcon} alt="Staking" /><p>Staking</p></li>
                        <li><img src={newsIcon} alt="News" /><p>AI Summarized Project News</p></li>
                        <li><img src={teamActivityIcon} alt="Team Activity" /><p>AI Summarized Team Activity</p></li>
                        <li><img src={pluginsIcon} alt="Plugins" /><p>Plugins</p></li>
                        <li className='beta-access'><div className='main'><img src={betaAccessIcon} alt="Plugins" /><p>Beta Access Pass</p></div><img src={unlockedIcon} alt="Plugins" /></li>
                    </ul>
                </span>
                {/* <span className="subscribe-wrap">
                    <p>Coming soon</p>
                    <span className="arrow-wrap">
                        <img src={arrowIcon} alt="Arrow"/>
                    </span>
                </span> */}
            </a>
        </div>
    </section>
);

export default Packages;
